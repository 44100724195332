import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { supabaseIntegration } from '@supabase/sentry-js-integration';
import { createClient } from '@supabase/supabase-js';
import { authError } from '$lib/stores/auth';

import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
	PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
	PUBLIC_ENV,
	PUBLIC_SUPABASE_URL,
	PUBLIC_SUPABASE_ANON_KEY
} from '$env/static/public';
import type { Database } from '$lib/types/supabase';

// Initialize Supabase client as a singleton
const supabaseClient = createClient<Database>(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY);

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_ENV,
	release: 'solarstream@1.0.0', // Set explicit release version
	integrations: [
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
		// Removing browser profiling integration as it causes policy violations
		// Sentry.browserProfilingIntegration(),
		supabaseIntegration(supabaseClient, Sentry, {
			tracing: true,
			breadcrumbs: true,
			errors: true
		}),
		Sentry.browserTracingIntegration({
			shouldCreateSpanForRequest: (url) => {
				return !url.startsWith(`${PUBLIC_SUPABASE_URL}/rest`);
			},
		})
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: Number(PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
	replaysOnErrorSampleRate: Number(PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
	profilesSampleRate: 0.0, // Disable profiles sampling completely
	debug: false
});

// Handle Supabase auth errors
const handleSupabaseAuthError = () => {
	const hash = window.location.hash;
	if (!hash) {
		// Clear any existing error when there's no hash
		authError.set({ type: null, description: null });
		return;
	}

	// Parse error parameters from hash
	const params = new URLSearchParams(hash.substring(1));
	const error = params.get('error');
	const errorDescription = params.get('error_description');

	if (errorDescription === 'Email+link+is+invalid+or+has+expired' || errorDescription === 'Email link is invalid or has expired') {
		authError.set({
			type: 'token_expired',
			description: 'Email link is invalid or has expired'
		});
		return;
	}

	if (error === 'access_denied') {
		authError.set({
			type: 'access_denied',
			description: errorDescription || 'Access denied'
		});
		return;
	}

	// Clear error if no matching conditions
	authError.set({ type: null, description: null });
};

// Run error handling on initial page load and subsequent navigations
handleSupabaseAuthError();

// Add event listener and store cleanup function
const cleanupHashChange = () => {
	const handler = (event: HashChangeEvent) => handleSupabaseAuthError();
	window.addEventListener('hashchange', handler);
	return () => window.removeEventListener('hashchange', handler);
};

// Add to window for cleanup on app destroy
(window as any).__cleanup = cleanupHashChange();

const error: HandleClientError = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);

	return {
		message: 'An unexpected error occurred.'
	};
};

export const handleError = Sentry.handleErrorWithSentry(error);
